<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="350"
  >
    <v-card>
      <v-system-bar
        window
        color="white"
      >
        <v-spacer></v-spacer>
        <v-btn small icon color="primary" @click="$emit('close')">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text class="black--text d-flex flex-column text-center justify-center pt-1 pb-8">
        <div class="mb-2">
          <v-icon large color="success">mdi-check-circle-outline</v-icon>
        </div>
        <div>
          <span class="subtitle-2 pt-1">{{ message }}</span>
        </div>
        <div class="pt-2">
          <v-btn
            small
            color="primary"
            @click="$emit('close')"
          >
            <span class="white--text">Ok</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SuccessDialog',
  props: {
    dialog: Boolean,
    message: String,
  },
}
</script>